import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "reactstrap"
import MySwiper from "../components/swiper/swiper"
import SampleForm from "../components/form/form"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import bsns from "../images/business.svg"
import finance from "../images/financial.svg"
import jigsaw from "../images/jigsaws.svg"
import report from "../images/report.svg"
import trophy from "../images/trophy.svg"
import "../fonts/fontawesome-all.min.css"
import leafWhite from "../../src/images/leaf-white.svg"
import leafDiv from "../../src/images/leaf.svg"
import logo from "../../src/images/logo.svg"

import femalePic from "../../src/images/female-pic.jpg"
import femalePic2 from "../../src/images/female-pic2.jpg"
import femalePic3 from "../../src/images/female-pic3.jpg"
import femalePic4 from "../../src/images/female-pic4.jpg"

const slugify = require("slugify")

// import img1 from "https://source.unsplash.com/user/neotronimz/600x400"
class IndexPage extends React.Component {
  render() {
    const siteTitle = "Spruce and Polish"
    const siteDescription = this.props.data.site.siteMetadata.description
    const posts = this.props.data.allMarkdownRemark.edges
    // console.log(posts)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div class="slider atf-section">
          <div class="slide1"></div>
          <div class="slide2"></div>
          <div class="slide3"></div>
          <div class="container-fluid atf-inner-cont">
            <div class="brandstamp text-center">
              <h1 class="mb-0 mt-0 text-white">
                SPRUCE <span class="and-text">AND</span> POLISH
              </h1>
              <h5 class="mt-0 mb-5 text-white">
                We want to be your neighborhood nail salon. We love to build
                relationships with our clients and really get to know you.
              </h5>
              <a class="global-btn main-cta" href="#">
                <i class="fas fa-phone"></i> Contact Us
              </a>
            </div>
          </div>
          <img
            class="leaf-img"
            src={leafWhite}
            alt="Spruce and Polish"
            alt="Spruce and Polish"
          />
        </div>

        <div class="container-fluid about-section p-0">
          <div class="container-fluid"></div>
          <div class="row">
            <div class="col-md-6 about-content-container">
              <div class="about-content">
                <h2 class="mt-3 mb-3">About Us</h2>
                <p class="mb-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <button class="global-btn mb-3" type="submit">
                  <i class="fas fa-book-reader"></i>Learn More
                </button>
              </div>
              <div class="about-content-border"></div>
            </div>
            <div class="col-md-6 about-img"></div>
          </div>
        </div>

        <div class="container-fluid value-props">
          <div class="container">
            <div class="row flex-column-reverse flex-md-row">
              <div class="overlay">
                <div></div>
              </div>
              <div class="col-lg-4 col-sm-6 services-col">
                <div class="services-cont">
                  <div class="vp-overlay">
                    <i class="fas fa-angle-up arrow-up"></i>
                    <h3 class="text-white mt-0 mb-3">Nail Salon</h3>
                    <p class="text-white mb-4 mt-0">
                      Eu vel noster scaevola molestiae. Lorem ipsum dolor sit
                      amet, nec cu omnium ponderum instructior.
                    </p>
                    <button class="global-btn" type="submit">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 products-col">
                <div class="products-cont">
                  <div class="vp-overlay">
                    <i class="fas fa-angle-up arrow-up"></i>
                    <h3 class="text-white mt-0 mb-3">
                      Organic Beauty Products
                    </h3>
                    <p class="text-white mb-4 mt-0">
                      Eu vel noster scaevola molestiae. Lorem ipsum dolor sit
                      amet, nec cu omnium ponderum instructior.
                    </p>
                    <button class="global-btn" type="submit">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-12 vp-content">
                <div class="vp-inner-content">
                  <h2 class="mb-3 mt-0">What We Do</h2>
                  <p class="mb-4">
                    Eu vel noster scaevola molestiae. Lorem ipsum dolor sit
                    amet, nec cu omnium ponderum instructior, eligendi gubergren
                    cotidieque te eam.
                    <br />
                  </p>
                  <button class="global-btn mb-3" type="submit">
                    <i class="fas fa-book-reader"></i>Read More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid menu-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-sm-12">
                <div class="menu-content">
                  <h2 class="mt-0 mb-3">Our Services</h2>
                  <p class="mb-4">
                    Lorem ipsum dolor sit amet, nec cu omnium ponderum
                    instructior, eligendi gubergren cotidieque te eam. Duis aute
                    irure dolor in reprehenderit in voluptate velit esse cillum
                    dolore eu fugiat nulla pariatur.
                  </p>
                  <a
                    class="global-btn"
                    href="https://res.cloudinary.com/dhexit7nv/image/upload/v1580787774/SPRUCE_POLISH_MENU_FINAL_pzloqo.pdf"
                    download
                  >
                    <i class="fas fa-download"></i>Download Menu
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6">
                <div class="menu-1-container">
                  <img src={logo} alt="Spruce and Polish Logo" />
                  <table class="text-center">
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <h5 class="mb-0 mt-5">ORGANIC PEDICURES</h5>
                          <p class="mb-2 sub-text">
                            our pedicures incorporate organic ingredients and
                            home remedies
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">BASIC</td>
                        <td class="text-right">$30 (~30 mins)</td>
                      </tr>
                      <tr>
                        <td class="text-left">SPECIAL</td>
                        <td class="text-right">$35 (~35 mins)</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="sub-text">
                          <p class="mb-3">CALLUS REMOVAL, SUGAR SCRUB</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">DELUXE</td>
                        <td class="text-right">$45 (~45mins)</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="sub-text">
                          <p class="mb-3">
                            CALLUS REMOVAL, SUGAR SCRUB, PARAFFIN WAX, MUD MASK
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2">
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <h6 class="mb-0">FOOT SOAKS</h6>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="sub-text">
                          <p class="mt-0 mb-3">
                            include epsom salt, baking soda, Himalayan sea salt
                            and organic essential oils procured from a local
                            business, ROOTS APOTHECARY
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2">
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <h6 class="mb-0">STEAMED TOWELS</h6>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="sub-text">
                          <p class="mt-0 mb-3">
                            are infused with essential oils
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <h6 class="mb-0">SOOTHING HOT STONE MASSAGE</h6>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="sub-text">
                          <p class="mt-0 mb-3">
                            is included with all pedicures
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2">
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <h6 class="mb-0">PEDIATRIC TUBS</h6>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="sub-text">
                          <p class="mt-0 mb-3">
                            are cleaned twice after each use, once with lysol
                            and once with bleach
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2">
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <h6 class="mb-0">PLASTIC LINERS</h6>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="sub-text">
                          <p class="mt-0 mb-3">
                            are available upon request but plastic does break
                            down in presence of oils and vigorous hot water and
                            is absorbed into the body. Our pedicures are
                            detoxifying, so we eliminate plastic when possible
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <h6 class="mb-0">SUGAR SCRUBS</h6>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="sub-text">
                          <p class="mt-0 mb-0">
                            are housemade with all organic ingredients: sugar,
                            aloe, raw honey, and essentials oils
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <img
                            class="table-divider"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <h5 class="mb-2">MANICURE</h5>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">POLISH/MANICURE</td>
                        <td class="text-right">$15</td>
                      </tr>
                      <tr>
                        <td class="text-left">GEL MANICURE</td>
                        <td class="text-right">$35</td>
                      </tr>
                      <tr>
                        <td class="text-left">GEL COLOR CHANGE</td>
                        <td class="text-right">$25</td>
                      </tr>
                      <tr>
                        <td class="text-left">PARAFFIN WAX</td>
                        <td class="text-right">$5</td>
                      </tr>
                      <tr>
                        <td class="text-left">EXTRA MASSAGE</td>
                        <td class="text-right">$5</td>
                      </tr>
                      <tr>
                        <td class="text-left">POLISH MASSAGE</td>
                        <td class="text-right">$10</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="sub-text">
                          <img
                            class="table-divider"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <p class="mt-0 mb-3">
                            One glass of complimentary wine is available
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6">
                <div class="menu-2-container">
                  <table class="text-center">
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <h5 class="mb-0 w-100">NAIL ENHANCEMENTS</h5>
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <p class="mb-0 mt-2">DIP POWDER</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left w-100">
                          <p class="mb-0 mt-2"></p>DIP MANICURE
                        </td>
                        <td class="text-right w-100">$45+</td>
                      </tr>
                      <tr>
                        <td class="text-left">DIP ONLY</td>
                        <td class="text-right">$40+</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <p class="mb-0">ACRYLIC</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">FULL SET POLISH</td>
                        <td class="text-right">$35+</td>
                      </tr>
                      <tr>
                        <td class="text-left">POLISH FILL</td>
                        <td class="text-right">$25+</td>
                      </tr>
                      <tr>
                        <td class="text-left">FULL SET GEL</td>
                        <td class="text-right">$50+</td>
                      </tr>
                      <tr>
                        <td class="text-left">GEL FILL</td>
                        <td class="text-right">$40+</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <p class="mb-0">HARD GEL OVERLAY</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">FULL SET HARD GEL</td>
                        <td class="text-right">$40+</td>
                      </tr>
                      <tr>
                        <td class="text-left">HARD GEL FILL</td>
                        <td class="text-right">$35+</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <p class="mb-0">PINK & WHITE ACRYLIC</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">FULL SET</td>
                        <td class="text-right">$50+</td>
                      </tr>
                      <tr>
                        <td class="text-left">FILL IN</td>
                        <td class="text-right">$45+</td>
                      </tr>
                      <tr>
                        <td class="text-left">OMBRE FULL SET</td>
                        <td class="text-right">$60+</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <p class="mb-0">KIDS</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <tbody>
                      <tr>
                        <td class="text-left m-0"></td>
                        <td class="text-center">8 & Under</td>
                        <td class="text-right">9-12</td>
                      </tr>
                      <tr>
                        <td class="text-left m-0">PEDICURE</td>
                        <td class="text-center w-50">$15</td>
                        <td class="text-right">$25</td>
                      </tr>
                      <tr>
                        <td class="text-left">MANICURE</td>
                        <td class="text-center">$10</td>
                        <td class="text-right">$10</td>
                      </tr>
                      <tr>
                        <td class="text-left">PEDICURE & MANICURE</td>
                        <td class="text-center">$25</td>
                        <td class="text-right">$35</td>
                      </tr>
                      <tr>
                        <td class="text-left">GEL MANICURE</td>
                        <td class="text-center">---</td>
                        <td class="text-right">$30</td>
                      </tr>
                      <tr>
                        <td class="text-left">PAINT TOES OR FINGERS</td>
                        <td class="text-center">$5</td>
                        <td class="text-right">$8</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="text-center">
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <p class="mb-0 mt-2">WAXING</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left w-100">EYEBROWS</td>
                        <td class="text-right w-100">$10</td>
                      </tr>
                      <tr>
                        <td class="text-left">LIPS</td>
                        <td class="text-right">$47</td>
                      </tr>
                      <tr>
                        <td class="text-left">CHIN</td>
                        <td class="text-right">$8</td>
                      </tr>
                      <tr>
                        <td class="text-left">FULL FACE</td>
                        <td class="text-right">$35</td>
                      </tr>
                      <tr>
                        <td class="text-left">UNDER ARMS</td>
                        <td class="text-right">$20</td>
                      </tr>
                      <tr>
                        <td class="text-left">HALF ARMS</td>
                        <td class="text-right">$20</td>
                      </tr>
                      <tr>
                        <td class="text-left">HALF LEGS</td>
                        <td class="text-right">$40</td>
                      </tr>
                      <tr>
                        <td class="text-left">FULL LEGS</td>
                        <td class="text-right">$60</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <p class="mb-0 mt-2">MISCELLANEOUS</p>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left w-100">POLISH CHANGE</td>
                        <td class="text-right w-100">$10</td>
                      </tr>
                      <tr>
                        <td class="text-left">TAKE-OFF ONLY</td>
                        <td class="text-right">$10</td>
                      </tr>
                      <tr>
                        <td class="text-left">FRENCH</td>
                        <td class="text-right">$5</td>
                      </tr>
                      <tr>
                        <td class="text-left">NAIL DESIGN</td>
                        <td class="text-right">$5+</td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <img
                            class="leaf-bullet"
                            src={leafDiv}
                            alt="Spruce and Polish"
                          />
                          <p class="mb-0 mt-2 mb-2">POLICIES</p>
                          <p class="sub-text mb-0">
                            APPOINTMENTS & CANCELLATION
                          </p>
                          <p class="sub-text  mb-2">
                            Please be considerate and call to cancel or change
                            appointment time. We strive to run appointments like
                            reservation for a restaurant. Life has a plenty of
                            surprises, so call us and cancel guilt-free.
                          </p>
                          <p class="sub-text mb-0">SANITIZATION</p>
                          <p class="sub-text mb-2">
                            SPURCE AND POLISH is at the vanguard of sanitization
                            techniques. All instruments are property washed,
                            soaked in barbicide, stored under UV lighting, and
                            routinely placed in an ultrasonic cleanser (same as
                            used by dentists). Unique to us, all pedicures tubs
                            are cleaned twice between customers once with lysol
                            and once with bleach.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="menu-overlay"></div>
            </div>
          </div>
        </div>

        <div class="container-fluid review-section">
          <div class="container">
            <div class="row flex-column-reverse flex-md-row">
              <div class="col-md-7">
                <MySwiper slidesperview={1}>
                  <div className="row justify-content-center">
                    <div className="col-8 mx-auto">
                      <div className="pt-5">
                        <div className="review-item text-center">
                          <div className="review-statement">
                            <img
                              class="rounded-circle"
                              src={femalePic}
                              width="100"
                              alt="Spruce and Polish Customer Review"
                            />
                            <p className="text-center pt-3">
                              LOVE this salon ! UT and Will (the owners) are so
                              sweet and make you feel like family. Beautiful,
                              clean spot with amazing color selection. Very
                              accommodating to schedule with. They stayed late
                              with me tonight to finish my nails. Above and
                              beyond customer service. Do yourself a favor and
                              go here !!! Customer for life here.
                            </p>
                          </div>
                          <div className="pt-3">
                            <span className="stars">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </span>
                            <h5 className="mb-0 mt-2">Melissa K.</h5>
                            <span className="text-muted">
                              Colorado Springs, CO
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-8 mx-auto">
                      <div className="pt-5">
                        <div className="review-item text-center">
                          <div className="review-statement">
                            <img
                              class="rounded-circle"
                              src={femalePic3}
                              width="100"
                              alt="Spruce and Polish Customer Review"
                            />
                            <p className="text-center pt-3">
                              UT and her fiancé are wonderful. I came in kind of
                              late and they were more than willing to
                              accommodate. Lovely couple and they are so
                              passionate about their business. They have
                              competitive pricing, and I definitely feel like I
                              got what I paid for.
                            </p>
                          </div>
                          <div className="pt-3">
                            <span className="stars">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </span>
                            <h5 className="mb-0 mt-2">Megan C.</h5>
                            <span className="text-muted">Fort Worth, TX</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-8 mx-auto">
                      <div className="pt-5">
                        <div className="review-item text-center">
                          <div className="review-statement">
                            <img
                              class="rounded-circle"
                              src={femalePic2}
                              width="100"
                              alt="Spruce and Polish Customer Review"
                            />
                            <p className="text-center pt-3">
                              Went here as my first nail salon in The Springs
                              and it was amazing ! My nails look great, the
                              customer service was impeccable, and I felt very
                              relaxed! I will be back for sure!
                            </p>
                          </div>
                          <div className="pt-3">
                            <span className="stars">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </span>
                            <h5 className="mb-0 mt-2">Kellyanne P.</h5>
                            <span className="text-muted">Mount Vernon, NY</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-8 mx-auto">
                      <div className="pt-5">
                        <div className="review-item text-center">
                          <div className="review-statement">
                            <img
                              class="rounded-circle"
                              src={femalePic4}
                              width="100"
                              alt="Spruce and Polish Customer Review"
                            />
                            <p className="text-center pt-3">
                              AMAZING SERVICE!! My nails look amazing and I love
                              the shape and design, I got paint on them when I
                              was well painting but they still look amazing and
                              they are all even! My cuticles didn't bleed! It
                              was easy to chat with the owners and they are a
                              well oiled machine!!
                            </p>
                          </div>
                          <div className="pt-3">
                            <span className="stars">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </span>
                            <h5 className="mb-0 mt-2">Bailey M.</h5>
                            <span className="text-muted">
                              San Francisco, CA
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </MySwiper>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-4">
                <div class="review-heading">
                  <h2 class="mb-3">Reviews</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.&nbsp;Per te convenire facilisis. Eu vel noster
                    scaevola molestiae.
                  </p>
                  <div class="row">
                    <div class="col col-md-2 col-sm-2">
                      <a
                        href="https://www.yelp.com/biz/spruce-and-polish-colorado-springs-3"
                        title="Yelp"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i class="fab fa-yelp social-links"></i>
                      </a>
                    </div>
                    <div class="col col-md-2 col-sm-2">
                      <a
                        href="https://www.google.com/maps/place/Spruce+And+Polish+(former+Pine+Creek+Nails)/@38.9678255,-104.7825579,15z/data=!4m5!3m4!1s0x0:0x5d73d0522e60bbfb!8m2!3d38.9678255!4d-104.7825579"
                        title="Google Plus"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i class="fab fa-google-plus-g social-links"></i>
                      </a>
                    </div>
                    <div class="col col-md-2 col-sm-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid p-0 contact-section">
          <div class="row">
            <div class="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12408.322757462243!2d-104.7825579!3d38.9678255!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5d73d0522e60bbfb!2sSpruce%20And%20Polish%20(former%20Pine%20Creek%20Nails)!5e0!3m2!1sen!2sph!4v1580097458130!5m2!1sen!2sph"
                width="100%"
                height="100%"
                frameborder="0"
                allowfullscreen=""
              ></iframe>
            </div>
            <div class="col-md-6 contact-clean">
              <form class="text-center" method="post">
                <h2 class="text-center mt-0 mb-2">Stay in Touch</h2>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur.
                  <br />
                </p>

                <SampleForm
                  form_name="Newsletter Form"
                  form_id="5e3d0f0e8395637206c2bee2"
                  form_classname="form-newsletter"
                  action="/thank-you"
                >
                  <div className="form-row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="first_name"
                          id="first_name"
                          required=""
                        />
                        <label>First Name</label>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="last_name"
                          id="last_name"
                          required=""
                        />
                        <label>Last Name</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <textarea
                          className="form-control w-100"
                          name="message"
                          id="message"
                          required=""
                        />
                        <label>Message</label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-4">
                    <button className="global-btn w-100" type="submit">
                      Send Message
                    </button>
                  </div>
                  <div className="form-group">
                    <div className="webriq-recaptcha" />
                  </div>
                </SampleForm>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/posts/" } }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            banner
            publishedDate(formatString: "MMMM DD, YYYY")
            excerpt
          }
        }
      }
    }
  }
`
